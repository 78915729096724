import Cookies from "js-cookie";

import * as Config from "./config.js";

import { setCountry } from "./actions/index";
import { setTrendInfo } from "./actions";
import store from "./store/index.js";



const circle_1 = "/static/circles/1.webp";
const circle_2 = "/static/circles/2.webp";
const circle_3 = "/static/circles/3.webp";
const circle_4 = "/static/circles/4.webp";
const circle_5 = "/static/circles/5.webp";
const circle_6 = "/static/circles/6.webp";


const logo_top = "/static/logo_top.webp";
const logo_bottom = "/static/logo_bottom.webp";

const flag_worldwide = "/static/earth-globe.png";

export const API_ADDR = "/query_bydates";
export const PREVIEW_API_ADDR = "/query_item";
export const API_KEY = "SomePPLC@llm3theSpac3C0wb0y";

const SCALING_STEPS = 6;

const circles_img = [
  circle_1,
  circle_2,
  circle_3,
  circle_4,
  circle_5,
  circle_6
];

export var twitter_country_names = {
  'ALL': 'Worldwide',
  'AE': 'United Arab Emirates',
  'DZ': 'Algeria',
  'AR': 'Argentina',
  'AU': 'Australia',
  'AT': 'Austria',
  'BH': 'Bahrain',
  'BE': 'Belgium',
  'BY': 'Belarus',
  'BR': 'Brazil',
  'CA': 'Canada',
  'CL': 'Chile',
  'CO': 'Colombia',
  'DK': 'Denmark',
  'DO': 'Dominican Republic',
  'EC': 'Ecuador',
  'EG': 'Egypt',
  'IE': 'Ireland',
  'FR': 'France',
  'GH': 'Ghana',
  'DE': 'Germany',
  'GR': 'Greece',
  'GT': 'Guatemala',
  'ID': 'Indonesia',
  'IN': 'India',
  'IL': 'Israel',
  'IT': 'Italy',
  'JP': 'Japan',
  'JO': 'Jordan',
  'KE': 'Kenya',
  'KR': 'Korea',
  'KW': 'Kuwait',
  'LB': 'Lebanon',
  'LV': 'Latvia',
  'OM': 'Oman',
  'MX': 'Mexico',
  'MY': 'Malaysia',
  'NG': 'Nigeria',
  'NL': 'Netherlands',
  'NO': 'Norway',
  'NZ': 'New Zealand',
  'PE': 'Peru',
  'PK': 'Pakistan',
  'PL': 'Poland',
  'PA': 'Panama',
  'PT': 'Portugal',
  'QA': 'Qatar',
  'PH': 'Philippines',
  'PR': 'Puerto Rico',
  'RU': 'Russia',
  'SA': 'Saudi Arabia',
  'ZA': 'South Africa',
  'SG': 'Singapore',
  'ES': 'Spain',
  'SE': 'Sweden',
  'CH': 'Switzerland',
  'TH': 'Thailand',
  'TR': 'Turkey',
  'GB': 'United Kingdom',
  'UA': 'Ukraine',
  'US': 'United States',
  'VE': 'Venezuela',
  'VN': 'Vietnam',
};

export var google_country_names = {
  'AR': 'Argentina',
  'AU': 'Australia',
  'AT': 'Austria',
  'BE': 'Belgium',
  'BR': 'Brazil',
  'CA': 'Canada',
  'CL': 'Chile',
  'CO': 'Colombia',
  'CZ': 'Czechia',
  'DK': 'Denmark',
  'EG': 'Egypt',
  'FI': 'Finland',
  'FR': 'France',
  'DE': 'Germany',
  'GR': 'Greece',
  'HK': 'Hong Kong',
  'HU': 'Hungary',
  'IN': 'India',
  'ID': 'Indonesia',
  'IE': 'Ireland',
  'IL': 'Israel',
  'IT': 'Italy',
  'JP': 'Japan',
  'KE': 'Kenya',
  'MY': 'Malaysia',
  'MX': 'Mexico',
  'NL': 'Netherlands',
  'NZ': 'New Zealand',
  'NG': 'Nigeria',
  'NO': 'Norway',
  'PH': 'Philippines',
  'PL': 'Poland',
  'PT': 'Portugal',
  'RO': 'Romania',
  'RU': 'Russia',
  'SA': 'Saudi Arabia',
  'SG': 'Singapore',
  'ZA': 'South Africa',
  'KR': 'South Korea',
  'SE': 'Sweden',
  'CH': 'Switzerland',
  'TW': 'Taiwan',
  'TH': 'Thailand',
  'TR': 'Turkey',
  'UA': 'Ukraine',
  'GB': 'United Kingdom',
  'US': 'United States',
  'VN': 'Vietnam',

}

export var country_names = google_country_names;


//country_names = sortOnKeys(country_names);

//var scales = scales_global['US'];
//var scales = [20000, 50000, 100000, 200000, 500000, 1000000, 2000000, 5000000, 10000000, 20000000];

export var scales = [];

var theme = 0; // 0 is Dark, 1 is Light

export class Vector2 {
  constructor(x, y) {
    this.x = x;
    this.y = y;
  }
}

function sortOnKeys(dict) {

  var sorted = [];
  for (var key in dict) {
    sorted[sorted.length] = key;
  }
  sorted.sort();

  var tempDict = {};
  for (var i = 0; i < sorted.length; i++) {
    tempDict[sorted[i]] = dict[sorted[i]];
  }

  return tempDict;
}

export function timeConverter(UNIX_timestamp) {
  var a = new Date(UNIX_timestamp);
  var months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  var year = a.getFullYear();
  var month = months[a.getMonth()];
  var date = a.getDate();
  var hour = a.getHours();
  var min = a.getMinutes();
  var sec = a.getSeconds();
  var time = date + "/" + (a.getMonth() + 1) + "/" + year;

  //date + " " + month + " " + year + " " + hour + ":" + min + ":" + sec;
  return time;
}


export function trafficToStep(traffic) {
  // Convert traffic to a step between 1 and 6 (for circle images, fonts, radius etc...)

  let idx = 0;

  for (var i = 0; i < scales.length; i++) {
    if (traffic >= scales[i])
      idx = i;
  }

  var res = parseInt(((1.0 * SCALING_STEPS - 1) / (scales.length - 1)) * (idx));
  //var res = parseInt(((1.0*SCALING_STEPS-1)/(scales.length-1))*(scales.indexOf(traffic)));


  return res;
}

export function wToRadius(w) {
  //var radis = [5, 5, 5.5, 6, 6, 7, 7.5, 8, 10, 10, 11] ;

  var radis = [5, 5.5, 6, 7, 7.5, 10]; // Should be the ammount of SCALING_STEPS
  //console.log( radis[scales.indexOf(w)] + 0.5);
  return radis[trafficToStep(w)] * 1.1 + 0.5;
}

export function wToFontSize(w, length) {
  //var sizes = [2, 2, 2.5, 3, 3, 4, 4.5, 5, 6, 6, 6.5] ;
  var sizes = [3, 3, 3.5, 4, 4.5, 5.5]; // should be the ammount of SCALING_STEPS

  return Math.max(sizes[trafficToStep(w)] * 2 / Math.sqrt(length), 1.4); // Reduce font size as length of text grows

}

export function wToImage(w) {
  return circles_img[trafficToStep(w)];

}

//var color2 = [0x26, 0xa4, 0xeb, 0xa0];
//var color1 = [101, 234, 124, 0xa0];
///var color1 = [0x36, 0xf4, 0xab, 0xa0];

var color2 = [0x46, 0xb4, 0xeb, 0xa0];
var color1 = [101, 234, 124, 0xa0];

export function rToColor(r) {
  var w1 = r - 6;

  var w2 = 1 - w1;
  var rgb = [
    Math.round(color1[0] * w1 + color2[0] * w2),
    Math.round(color1[1] * w1 + color2[1] * w2),
    Math.round(color1[2] * w1 + color2[2] * w2),
  ];
  return "rgb(" + rgb[0] + "," + rgb[1] + "," + rgb[2] + ",0.8)";
}

export function normalize(s) {
  return s.toLowerCase().replace(/[.,\/#!$%\^&\*;:{}=\-_`'\"~()]/g, "");
}

export function compareDuplicates(trend_a, trend_b) {
  let title_a = normalize(trend_a.title);
  let title_b = normalize(trend_b.title);

  return (
    title_a === title_b &&
    Math.abs(trend_a.date - trend_b.date) <= Config.DUPLICATE_DATERANGE
  );
}

export function duplicateFavor(trend_a, trend_b) {
  if (trend_a.weight > trend_b.weight) {
    return trend_a;
  } else if (trend_a.weight < trend_b.weight) {
    return trend_b;
  } else if (trend_a.date > trend_b.date) {
    return trend_a;
  } else if (trend_a.date < trend_b.date) {
    return trend_b;
  } else if (trend_a.title > trend_b.title) {
    return trend_a;
  } else {
    return trend_b;
  }
}

var addRule = function (sheet, selector, styles) {
  if (sheet.insertRule)
    return sheet.insertRule(
      selector + " {" + styles + "}",
      sheet.cssRules.length
    );
  if (sheet.addRule) return sheet.addRule(selector, styles);
};



function replaceAll(s, search, replacement) {
  return s.split(search).join(replacement);
}

export function decodeHtmlSpecial(text) {
  if (text == undefined) return text;
  let res = replaceAll(text, "&#39;", "'");
  res = replaceAll(res, "&nbsp;", " ");
  res = replaceAll(res, "&quot;", '"');
  res = replaceAll(res, "&amp;", '&');
  res = replaceAll(res, "&lt;", '<');
  res = replaceAll(res, "&gt;", '>');
  return res;
}

export function showCountryList() {
  let list = document.getElementsByClassName("countryList")[0];
  list.style["display"] = "flex";
  //document.getElementsByClassName("bg")[0].style.boxShadow = "inset 3px 3px 4px 0px rgba(0, 0, 0, 0.3)";
  document.getElementById("countryListBackground").style.display = "block";
}

export function hideCountryList() {
  let list = document.getElementsByClassName("countryList")[0];
  list.style["display"] = "none";
  //document.getElementsByClassName("bg")[0].style.boxShadow = "-2px -2px 4px rgba(255,255,255,0.1),  5px 5px 4px rgba(0, 0 ,0 ,0.1)";
  document.getElementById("countryListBackground").style.display = "none";
}

export function toggleCountryList() {
  let list = document.getElementsByClassName("countryList")[0];
  if (list.style["display"] == "none") {
    showCountryList();
  }
  else {
    hideCountryList();
  }
}


export function changeCountry(newCountry) {

  scales = Config.COUNTRY_TRAFFICS[newCountry];
}

export function getFavouriteCountries() {

  let cookie_val = Cookies.get("Favourites");

  if (typeof (cookie_val) == "undefined")
    return [];

  else return cookie_val.split(";");
}

export function addFavouriteCountry(country) {
  let favs = getFavouriteCountries();
  favs.push(country);


  Cookies.set("Favourites", favs.join(";"));
}

export function removeFavouriteCountry(country) {
  let new_val = getFavouriteCountries();
  new_val.splice(new_val.indexOf(country), 1);

  Cookies.set("Favourites", new_val.join(";"));
}


export function highlightCountry(val) {
  let countryButtons = document.getElementsByClassName("countryListItem");
  for (let i = 0; i < countryButtons.length; i++) {
    if (countryButtons[i].id == val) {
      countryButtons[i].style.color = '#ffffff';
    } else {
      countryButtons[i].style.color = '#bbbbbb';
    }
  }

}

export function traffic_label(weight) {
  if (weight >= 1000000) {
    return parseInt(weight / 1000000) + "M+";
  } else {
    return parseInt(weight / 1000) + "K+";
  }
}


var logo_is_spinning = false;
var logo_spin_interval = null;

export function set_logo_spinner() {
  var logo_top = document.getElementsByClassName("App-logo-top")[0];
  //var clone = logo_top.cloneNode(true);
  //logo_top.parentNode.replaceChild(clone, logo_top);


  if (logo_is_spinning)
    return;
  logo_top.style.animation = "spin 1000 1s ease-in-out";
  logo_is_spinning = true;

  setTimeout(function () {
    logo_is_spinning = false;
    logo_top.style.animation = '';
  }, 100000);

  logo_spin_interval = setInterval(function () {
    if (!logo_is_spinning) {
      logo_top.style.animation = '';
      clearInterval(logo_spin_interval);
    }
  }, 1000)

  //logo_top.
}


export function loading_started() {
  set_logo_spinner();
  logo_is_spinning = true;
}

export function loading_finished() {
  logo_is_spinning = false;
}





export function showTooltip(trend) {
  /*
  let title = trend.title;
  let weight = trend.weight;
  let date = trend.date;
  let desc = trend.desc;

  let country = window.store.getState().country;
  let id = country+";"+(date/1000)+";"+title;

  trend.sharelink = "https://theactual.news/trend/"+encodeURIComponent(btoa(encode_utf8(id)));
*/
  let tooltip = document.getElementById("tooltip");
  let tooltipScreen = document.getElementById("tooltipScreen");

  let links = tooltip.getElementsByClassName("shareLink");

  for (var i = 0; i < links.length; i++)
    tooltip.removeChild(links[i]);

  window.store.dispatch(setTrendInfo(trend));

  window.tooltip = true;
  tooltipScreen.style.display = "block";

  setTitleAndDesc(trend);
}


export function encode_utf8(s) {
  return unescape(encodeURIComponent(s));
}

export function decode_utf8(s) {
  return decodeURIComponent(escape(s));
}


export function htmlDecode(input) {
  var e = document.createElement('textarea');
  e.innerHTML = input;
  // handle case of empty input
  return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
}

export function getCountryFlag(code) {
  code = code.toLowerCase();

  if (code == "all")
    return flag_worldwide;

  return "https://raw.githubusercontent.com/lipis/flag-icon-css/master/flags/4x3/" +
    code +
    ".svg";
}

export function setTitleAndDesc(trend) {
  document.getElementsByTagName("title")[0].innerHTML = "Trendy - " + trend.title;
}

export function resetTitleAndDesc() {
  document.getElementsByTagName("title")[0].innerHTML = "Trendy - Trending Topics & Search Trends";
}
