export var SVG_SCALING = 150;

export var US_SZ_FACTOR = 1;
export var IL_SZ_FACTOR = 10; // Google trends weights ratio between countries

export var UPDATE_INTERVAL = 50;
export var BUBBLE_SPEED = 0.5;

export var MIN_DELTA_SPEED = 0.00001;

export var MAX_POSITION_NOISE = 2;

export var FETCH_LIMIT = 500;
export var DISPLAY_LIMIT = 20;

export var DEFAULT_TIMEWIN = 2;
export var DEFAULT_COUNTRY = "US"
export var DUPLICATE_DATERANGE = 1000 * 60 * 60 * 24;


export var MAX_DAYS_BACK = 7;

// from local json file
export var COUNTRY_TRAFFICS = require('./traffics.json');




