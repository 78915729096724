import React from "react";
import Cookies from 'js-cookie';

import store from "./store/index";

import * as Util from "./Util.js";
import * as Config from "./config.js";
import { setCountry } from "./actions";

// Icons paths
const death_icon = "/static/icons/death.png";
const money_icon = "/static/icons/money.png";
const gossip_icon = "/static/icons/gossip.png";
const sports_icon = "/static/icons/sports.png";
const politics_icon = "/static/icons/politics.png";
const crime_icon = "/static/icons/crime.png";
const doodle_icon = "/static/icons/doodle.png";
const tech_icon = "/static/icons/tech.png";
const virus_icon = "/static/icons/virus.png";
const music_icon = "/static/icons/music.png";


const btn_share_icon = "/static/share.png";


var g_drag_origin_x = 0;
var g_drag_origin_y = 0;
var g_drag_dest_x = 0;
var g_drag_dest_y = 0;
var g_dragging = false;

var svg;
var pt;



export function setDragOrigin(e) {
  if(!svg) {
    svg = document.getElementsByClassName("TrendsSvg")[0];
    pt = svg.createSVGPoint();
  }

  if (e.hasOwnProperty("touches")) {
    g_drag_origin_x = e.touches[0].clientX;
    g_drag_origin_y = e.touches[0].clientY;
  } else {
    g_drag_origin_x = e.clientX;
    g_drag_origin_y = e.clientY;
  }

  pt.x = g_drag_origin_x;
  pt.y = g_drag_origin_y;

  let actual = pt.matrixTransform(svg.getScreenCTM().inverse());

  g_drag_origin_x = actual.x;
  g_drag_origin_y = actual.y;

  g_drag_dest_x = g_drag_origin_x;
  g_drag_dest_y = g_drag_origin_y;
  g_dragging = true;
}

export function setDragDest(e) {
  if (g_dragging) {


    if (e.hasOwnProperty("touches")) {
      g_drag_dest_x = e.touches[0].clientX;
      g_drag_dest_y = e.touches[0].clientY;
    } else {
      g_drag_dest_x = e.clientX;
      g_drag_dest_y = e.clientY;
    }

    pt.x = g_drag_dest_x;
    pt.y = g_drag_dest_y;

    let actual = pt.matrixTransform(svg.getScreenCTM().inverse());
  
    g_drag_dest_x = actual.x;
    g_drag_dest_y = actual.y;
  }
}

export function setDragStop(e) {
  g_dragging = false;
}


function tagPath(name) {

  // New from classifier
  switch (name) {
    case "money":
      return money_icon;
    case "sports":
      return sports_icon;
    case "politics":
      return politics_icon;
    case "crime":
      return crime_icon;
    case "covid":
      return virus_icon;
    case "technology":
      return tech_icon;
    case "entertainment":
      return gossip_icon;
  }
 
}


function tagsSummary(tagsArr, x, y, animationOffset) {
  

    let scaling = 4;
    if (tagsArr.length > 2)
        scaling = 6;
  return tagsArr.map((v, i) => {
    return (
      <image
        key={i}
        x={ - tagsArr.length/scaling + x - scaling/2 + i*scaling/2}
        y={y}
        width={scaling/(tagsArr.length)}
        height={scaling/(tagsArr.length)}
        href={tagPath(v)}
        alt={v}
      > 
       
      </image>
    );
  });
}


class TrendBubble extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      tags: [],
      //position: { x: this.props.trend.x, y: this.props.trend.y },
      animationOffset : Math.random() * 2 - 1
    };

    this.style = {animationDuration: `${6 + (Math.random() * 2 - 1)}s`};    
    
  }

  componentDidMount() { 
    this._ismounted = true;
  }
  
  componentWillUnmount() {
     this._ismounted = false;
  }



  render() {

      this.scale = this.props.trend.r*2;

    this.children_style = {
      //fill: Util.rToColor(this.props.trend.r),
      width: this.scale,
      height: this.scale,
      fontFamily: "mainFont",
      //transform: `translate(${10}px, ${-10}px)`,
    };

      
      //this.state.position = { x: this.props.trend.x, y: this.props.trend.y };
      this.style = {
        animationDuration: this.style.animationDuration,
          transformOrigin: `${this.props.trend.x}px ${this.props.trend.y}px`,
          cursor: 'pointer'
        }


      this.state.tags = [this.props.trend.category];

    return (
        <a className="BubbleHref" href={this.props.trend.sharelink}
               onClick={(e) => {
       e.preventDefault(); // don't follow link
   }}>
      <g
      
        className={"TrendBubbleWithText noSelect "+ (this.props.visited ? "TrendBubbleVisited"  : "")}
        style={this.style}
        onClick={(e) => {
            Util.showTooltip(this.props.trend);

            // Notify the android java app
            if ("jsiBubbleClick" in window)
                window.jsiBubbleClick.performClick();

            this.props.trend.visited = true;
        }}
      >
         
        <image
          style={this.children_style}
          x={this.props.trend.x}
          y={this.props.trend.y}
          width={this.children_style.width}
          height={this.children_style.height}
          href={Util.wToImage(this.props.trend.weight)}
          alt={this.props.trend.title}
          className="TrendBubble noSelect"
          transform={`translate(${-this.scale/2} ${-this.scale/2})`}
        >
        </image>

        <text
          style={this.children_style}
          fontSize={this.props.trend.fontSize+"px"}
          x={this.props.trend.x}
          y={this.props.trend.y}
          textAnchor="middle"
          dy=".3em"
        >

          <tspan x={this.props.trend.x} dy="0em">
            {this.props.trend.title}
          </tspan>
        </text>
        <g className="tags" dy="1.2em" >
          
          {" "}
          {tagsSummary(
            this.state.tags,
            this.props.trend.x,
            this.props.trend.y+0.7,
            this.state.animationOffset
          )}{" "}
        </g>
      </g>
</a>
    );
  }
}



export class TrendsVizBubble extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      maxDate: 0,
      minDate: 0,
      trends: [],
      maxFetchedDate: 0,
      currentCountry: Cookies.get("Country") || store.getState().country,
      previewShown: false,
    };

    svg = null;
    this.currentCountryPrivate = Cookies.get("Country") || "";
    

    //store.dispatch(setCountry(this.currentCountryPrivate));

    

    this.unsubscribe = store.subscribe(() => {     
      //console.log("[DBG] S");
      // Refetch on country change
      let country = store.getState().country;
      
      //console.log("[DBG] ", country, this.currentCountryPrivate);
      
     
      if (this.currentCountryPrivate != country) {
        //console.log("[DBG] sub calling updateCountry ", country);
        this.updateCountry(country);
      }
      
      // Filter trends to new time scope
      this.filterTrends();
    });
  }

  componentDidMount() {
    this.reloadTrends();
  }

  updateCountry(country) {
    
    this.setState({ currentCountry: country });
    this.currentCountryPrivate = country;

    //console.log("[DBG] Setting country to ", country);
    Cookies.set("Country", country);
    this.reloadTrends();
    this.filterTrends();

  }

  cleanTrends(data, depth=2) {
    // Mainly remove duplicates
    window.tst = data;
    let filtered = [];

    for (var k = 0; k < depth; k++) {
      filtered = [];
      for (var i = 0; i < data.length; i++) {
        let trend_a = data[i];
        let isDuplicate = false;
        let trend_dup = trend_a;

        // Automatically remove google doodle, UGLY HACK
        if (Util.normalize(trend_a.title).includes("google doodle")) {
          continue;
        }

        for (var j = 0; j < data.length; j++) {
          let trend_b = data[j];

          if (trend_a == trend_b) continue;

          if (Util.compareDuplicates(trend_a, trend_b)) {
            //console.log(trend_a,trend_b);
            //console.log("----------");
            isDuplicate = true;
            trend_dup = trend_b;
          } else {
          }
        }
        if (isDuplicate) {
          trend_a = Util.duplicateFavor(trend_a, trend_dup);
        }

        if (filtered.indexOf(trend_a) == -1) filtered.push(trend_a);
      }

      data = filtered; // Prepare next iteration
  }

    return filtered;
  }


  setTrends(data) {
    //console.log("DEBUG: fetched " + data.length + " entries");
    //console.log(data);
    this.setState({ trends: [] });
    //data = data.reverse();

    this.setState({
      maxFetchedDate: Math.max.apply(
        Math,
        data.map(function (t) {
          return t.date;
        })
      ),
    });

    //console.log("Done fetch");

    let sharedDatesCount = {};
    let dateIndex = {};

    // Populate shared dates dict, tells how many entries in each date
    data.map((t) => {
      this.sharedDateKey(t.date) in sharedDatesCount
        ? (sharedDatesCount[this.sharedDateKey(t.date)] += 1)
        : (sharedDatesCount[this.sharedDateKey(t.date)] = 1);
      dateIndex[this.sharedDateKey(t.date)] = 1;
    });

    for (var i = 0; i < data.length; i++) {
      data[i].y =
        this.props.svgHeight *
        (0.05 +
          (0.95 * (this.state.maxFetchedDate - data[i].date)) /
            (this.state.maxFetchedDate - this.state.minDate));

        data[i].x = this.props.svgWidth / 2;
      /*data[i].x =
        this.props.svgWidth *
        (1 -
          (dateIndex[this.sharedDateKey(data[i].date)]++ /
            (sharedDatesCount[this.sharedDateKey(data[i].date)] + 1)) *
            0.9);
            */

      // Adding small noise
      data[i].x += Math.random() * Config.MAX_POSITION_NOISE;
      data[i].y += Math.random() * Config.MAX_POSITION_NOISE;

      data[i].r = Util.wToRadius(data[i].weight);
      data[i].fontSize = Util.wToFontSize(
        data[i].weight,
        data[i].title.length
      );

      data[i].font = this.font;
      data[i].visited = false;
    }

    this.setState({ alltrends: this.cleanTrends(data) });

    //console.log(this.state.trends);

    this.filterTrends();
  }


  // Bubble view
  reloadTrends() {
    let storeState = store.getState();
    this.timeWindow = storeState.timeWindow;

    Util.changeCountry(this.currentCountryPrivate);
    
    // preview mode
    if (this.props.previewTrend && !this.state.previewShown) {
      
      fetch(
        Util.PREVIEW_API_ADDR +
          "?p=" +
          Util.API_KEY +
          "&id=" + this.props.previewTrend.split("/trend/")[1]
      )
        .then((response) => response.json())
        .then((data) => { if (data.length > 0) {
          let trends = data.map((entry) => ({
            title: entry.title,
            date: entry.published_parsed * 1000,
            weight: entry.ht_approx_traffic,
            desc: entry.ht_news_item_snippet,
            img: entry.ht_picture,
            source: entry.ht_news_item_source,
            source_url: entry.ht_news_item_url, 
          }));
          //console.log("Found");
          Util.showTooltip(trends[0]);
          this.setState({previewShown : true});
          
        } else {
          //console.log("Not found");
        }});

    } 

      this.setState({loading: true});
      Util.loading_started();

      let from_time = parseInt(storeState.maxDate / 1000 - Config.MAX_DAYS_BACK * 24*60*60);
      // Round down to nearest hour for caching purposes, consider rounding to 24hours
      from_time = from_time - from_time % (60*60);

      // Regular fetch in bubble view
      fetch(
        Util.API_ADDR +
          "?p=" +
          Util.API_KEY +
          "&from=" +
          from_time +
          "&country=" +
          this.currentCountryPrivate +
          "&limit=" +
          Config.FETCH_LIMIT
      )
        .then((response) => response.json())
        .then((data) =>
          this.setTrends(
            data.map((entry) => ({
              title: entry.title,
              date: entry.published_parsed * 1000,
              weight: entry.ht_approx_traffic,
              desc: entry.ht_news_item_snippet,
              img: entry.ht_picture,
              source: entry.ht_news_item_source,
              source_url: entry.ht_news_item_url,
              sharelink: "/trend/"+encodeURIComponent(btoa(Util.encode_utf8(this.currentCountryPrivate+";"+(entry.published_parsed)+";"+entry.title))),
              category: entry.category
            }))
          )
        ).then(()=>this.setState({loading: false})
        ).then(()=>{Util.loading_finished();})

   
        
      
    this.setState({ maxDate: storeState.maxDate, minDate: storeState.minDate });
    

      window.requestAnimationFrame(this.updatePositions.bind(this));
    window.prerenderReady = true;
    
    }

  componentWillUnmount() {
    this.unsubscribe();
    clearInterval(this.interval);
  }
  
  filterTrends() {

    
    let storeState = store.getState();


    let limDate = parseInt(storeState.minDate );
    let query = storeState.searchQuery;


    // Remove trends with further dates than limit
    let currentData = this.state.alltrends;

    if (!currentData)
      return;


    let res = currentData.filter( t => t.date > limDate);
    
    res = res.filter( t=> (Util.normalize(t.title).indexOf(Util.normalize(query)) != -1 || Util.normalize(t.desc).indexOf(Util.normalize(query)) != -1))

    // Sort by traffic desc
    res = res.sort((a, b) => (a.weight > b.weight) ? -1 : 1);
    
    this.setState({trends: res.slice(0, Config.DISPLAY_LIMIT)});         
  }


    updatePositions(timestamp) {
        //console.log(timestamp);
        if (this.startTimeStamp === undefined) {
            this.startTimeStamp = timestamp;
        }
        const elapsed = timestamp - this.startTimeStamp;

        if (this.previousTimeStamp !== timestamp) {
            // Math.min() is used here to make sure the element stops at exactly 200px

            let trends = this.state.trends;

            for (var i = 0; i < trends.length; i++) {
                let trend_a = trends[i];
                for (var j = 0; j < trends.length; j++) {
                    let trend_b = trends[j];

                    let dist = Math.sqrt(
                        (trend_a.x - trend_b.x) ** 2 + (trend_a.y - trend_b.y) ** 2
                    );

                    let delta_x = 0;
                    let delta_y = 0;

                    if (trend_a.r + trend_b.r > dist) {
                        delta_x = trend_a.x - trend_b.x;
                        delta_y = trend_a.y - trend_b.y;

                        delta_x *= Config.BUBBLE_SPEED / (dist ** 2 + 0.0001);
                        delta_y *= Config.BUBBLE_SPEED / (dist ** 2 + 0.0001);
                    }

                    if (Math.abs(delta_x) > Config.MIN_DELTA_SPEED) {
                        trends[i].x += delta_x;
                        trends[j].x -= delta_x;
                    }
                    if (Math.abs(delta_y) > Config.MIN_DELTA_SPEED) {
                        trends[i].y += delta_y;
                        trends[j].y -= delta_y;
                    }
                }

                // Boundaries
                let delta_x = 0;
                let delta_y = 0;

                if (trend_a.x - trend_a.r < 0) {
                    delta_x -= trend_a.x - trend_a.r;
                } else if (trend_a.x + trend_a.r > this.props.svgWidth) {
                    delta_x += this.props.svgWidth - (trend_a.x + trend_a.r);
                }

                if (trend_a.y - trend_a.r < 0) {
                    delta_y -= trend_a.y - trend_a.r;
                } else if (trend_a.y + trend_a.r > this.props.svgHeight) {
                    delta_y += this.props.svgHeight - (trend_a.y + trend_a.r);
                }

                delta_y *= Config.BUBBLE_SPEED / 10;
                delta_x *= Config.BUBBLE_SPEED / 10;

                // Add draging input to deltas
                let drag_x = (g_drag_dest_x - g_drag_origin_x);
                let drag_y = (g_drag_dest_y - g_drag_origin_y);
                let drag_factor = 20/Math.sqrt(
                    (trend_a.x - g_drag_origin_x) ** 2 + (trend_a.y - g_drag_origin_y) ** 2
                );

                delta_x +=  drag_factor*drag_x/50;
                delta_y +=  drag_factor*drag_y/50;

                // Declining the drag vector magnitude
                g_drag_origin_x += drag_x/300;
                g_drag_origin_y += drag_y/300;



                delta_x *= Math.min(0.1 * elapsed, 1);
                delta_y *= Math.min(0.1 * elapsed, 1);

                if (Math.abs(delta_x) > Config.MIN_DELTA_SPEED) trends[i].x += delta_x;
                if (Math.abs(delta_y) > Config.MIN_DELTA_SPEED) trends[i].y += delta_y;

                // Debug
                //trends[i].x = g_drag_dest_x;
                //trends[i].y = g_drag_dest_y;
            }

            this.setState({ trends: trends });
        }

        this.previousTimeStamp = timestamp;
        window.requestAnimationFrame(this.updatePositions.bind(this));

    }

  sharedDateKey(date) {
    return parseInt(date / 1000 / 60 / 60 / (this.timeWindow * 2));
  }

  render() {
    //console.log("Viz render");
    //console.log(this.state.maxFetchedDate);

    /*
                <g className="timescale-group">
              <TimeScale
                svgWidth={this.props.svgWidth}
                svgHeight={this.props.svgHeight}
              />
            </g>
    */
    return (
      <div className="TrendsContainer">
        <svg
          className="TrendsSvg"
          viewBox={"0 0 " + this.props.svgWidth + " " + this.props.svgHeight}
          preserveAspectRatio="xMaxYMin meet"
          onMouseDown={setDragOrigin}
          onMouseMove={setDragDest}
          onMouseUp={setDragStop}

          onTouchStart={setDragOrigin}
          onTouchMove={setDragDest}
          onTouchEnd={setDragStop}

        >
          <defs>

  </defs>
          <g className="top-group" >


            <g className="main-group" >
              {this.state.maxFetchedDate != 0 &&
                this.state.trends.map((trend) => {
                  let k = this.state.trends.indexOf(trend);
                  //console.log(trend);

                  let res = (
                    <TrendBubble
                      key={k}
                      trend={trend}
                      visited={trend.visited}
                    />
                  );
                  // debug
                  //console.log(res.props.title+":"+res.props.y);
                  if(!this.state.loading)
                    return res;
                })}
              ;
            </g>
          </g>
        </svg>
      </div>
    );
  }
}





export class TrendsVizList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      maxDate: 0,
      minDate: 0,
      trends: [],
      maxFetchedDate: 0,
      currentCountry: Cookies.get("Country") || store.getState().country,
      previewShown: false,
      sortedBy: "date",
      sortOrder: -1,
    };
      this.startTimeStamp = undefined;
      this.previousTimeStamp = 0;
    this.currentCountryPrivate = Cookies.get("Country") || "";
      
    this.unsubscribe = store.subscribe(() => {     
      //console.log("[DBG] S");
      // Refetch on country change
      let country = store.getState().country;
      
      //console.log("[DBG] ", country, this.currentCountryPrivate);
      
    
      if (this.currentCountryPrivate != country) {
        //console.log("[DBG] sub calling updateCountry ", country);
        this.updateCountry(country);
      } 
      
      // Filter trends to new time scope
      this.filterTrends();
    });
  }

  componentDidMount() {
    this.reloadTrends();
  }

  updateCountry(country) {
    
    this.setState({ currentCountry: country });
    this.currentCountryPrivate = country;

    //console.log("[DBG] Setting country to ", country);
    Cookies.set("Country", country);
    this.reloadTrends();
    this.filterTrends();

  }

  cleanTrends(data, depth=2) {
    // Mainly remove duplicates
    window.tst = data;
    let filtered = [];

    for (var k = 0; k < depth; k++) {
      filtered = [];
      for (var i = 0; i < data.length; i++) {
        let trend_a = data[i];
        let isDuplicate = false;
        let trend_dup = trend_a;

        // Automatically remove google doodle, UGLY HACK
        if (Util.normalize(trend_a.title).includes("google doodle")) {
          continue;
        }

        for (var j = 0; j < data.length; j++) {
          let trend_b = data[j];

          if (trend_a == trend_b) continue;

          if (Util.compareDuplicates(trend_a, trend_b)) {
            //console.log(trend_a,trend_b);
            //console.log("----------");
            isDuplicate = true;
            trend_dup = trend_b;
          } else {
          }
        }
        if (isDuplicate) {
          trend_a = Util.duplicateFavor(trend_a, trend_dup);
        }

        if (filtered.indexOf(trend_a) == -1) filtered.push(trend_a);
      }

      data = filtered; // Prepare next iteration
  }

    return filtered;
  }

  setTrends(data) {
    //console.log("DEBUG: fetched " + data.length + " entries");
    //console.log(data);
    this.setState({ trends: [] });
    //data = data.reverse();

    this.setState({
      maxFetchedDate: Math.max.apply(
        Math,
        data.map(function (t) {
          return t.date;
        })
      ),
    });

    //console.log("Done fetch");


    this.setState({ alltrends: this.cleanTrends(data) });

    //console.log(this.state.trends);

    this.filterTrends();
  }

  reloadTrends() {    
    let storeState = store.getState();
    this.timeWindow = storeState.timeWindow;

    Util.changeCountry(this.currentCountryPrivate);

    // preview mode
    this.setState({loading: true});
    Util.loading_started();

    if (this.props.previewTrend && !this.state.previewShown) {
      fetch(
        Util.PREVIEW_API_ADDR +
          "?p=" +
          Util.API_KEY +
          "&id=" + this.props.previewTrend.split("/trend/")[1]
      )
        .then((response) => response.json())
        .then((data) => { if (data.length > 0) {
          let trends = data.map((entry) => ({
            title: entry.title,
            date: entry.published_parsed * 1000,
            weight: entry.ht_approx_traffic,
            desc: entry.ht_news_item_snippet,
            img: entry.ht_picture,
            source: entry.ht_news_item_source,
            source_url: entry.ht_news_item_url, 
          }));
          //console.log("Found");
          Util.showTooltip(trends[0]);
          
          this.setState({previewShown : true});
          
        } else {
          //console.log("Not found");
        }});

    }

      this.setState({loading: true});
      Util.loading_started();
    
      let from_time = parseInt(storeState.maxDate / 1000 - Config.MAX_DAYS_BACK * 24*60*60);
      // Round down to nearest hour for caching purposes, consider rounding to 24hours
      from_time = from_time - from_time % (60*60);
      
      fetch(
        Util.API_ADDR +
          "?p=" +
          Util.API_KEY +
          "&from=" +
          from_time +
          "&to=" +
          parseInt(storeState.maxDate / 1000) +
          "&country=" +
          this.currentCountryPrivate +
          "&limit=" +
          Config.FETCH_LIMIT
      )
        .then((response) => response.json())
        .then((data) =>
          this.setTrends(
            data.map((entry) => ({
              title: entry.title,
              date: entry.published_parsed * 1000,
              weight: entry.ht_approx_traffic,
              desc: entry.ht_news_item_snippet,
              img: entry.ht_picture,
              source: entry.ht_news_item_source,
              source_url: entry.ht_news_item_url, 
            }))
          )
        ).then(()=>this.setState({loading: false})
        ).then(()=>this.setState({sortedBy: "date", sortOrder: -1})
        ).then(()=>{Util.loading_finished()});
        
  ;

   
    this.setState({ maxDate: storeState.maxDate, minDate: storeState.minDate });
    


    window.prerenderReady = true;
    
  }

  componentWillUnmount() {
    this.unsubscribe();
    clearInterval(this.interval);
  }
  
  filterTrends() {
    let storeState = store.getState();


    let limDate = parseInt(storeState.minDate);
    let query = storeState.searchQuery;


    // Remove trends with further dates than limit
    let currentData = this.state.alltrends;

    if (!currentData)
      return;

    // Slice to chosen time window
    let res = currentData.filter( t => t.date > limDate);

    // Apply search text box filter
    res = res.filter( t=> (Util.normalize(t.title).indexOf(Util.normalize(query)) != -1 || Util.normalize(t.desc).indexOf(Util.normalize(query)) != -1))
    
    this.setState({trends: res}, () => this.sortTrends(this.state.sortedBy, this.state.sortOrder));

}


sortTrends(by, order) {
let res = this.state.trends;

res = res.sort(
  (a, b) => ((a[by] > b[by]) ? 1 : -1) * order
);


this.setState({trends: res, sortedBy: by, sortOrder: order});
return res;
}

getHeaderText(field) {
  let res = field.toLowerCase();

  if (res == "traffic")
    res = "weight";

  if (res == this.state.sortedBy)
    res = res + " " + ((this.state.sortOrder==-1) ? "▼"  : "▲")

  res = res.replace("weight", "traffic");

  return res[0].toUpperCase()+res.slice(1);

}


markTrendVisited(k) {
  let res = this.state.trends;
  res[k].visited = true;
  this.setState({trends: res});
}

  render() {
    //console.log("Viz render");
    //console.log(this.state.maxFetchedDate);

    /*
                <g className="timescale-group">
              <TimeScale
                svgWidth={this.props.svgWidth}
                svgHeight={this.props.svgHeight}
              />
            </g>
    */
    return (
      <div className="TrendsContainer">
  <div className="trendsListViewHeader">
          <div className="trendListItemTitle"> <span onClick={()=>{this.sortTrends("title", this.state.sortedBy == "title" ? this.state.sortOrder * -1 : 1)}}> {this.getHeaderText("Title")} </span> </div>
    <div className="trendListItemDate"> <span onClick={()=>{this.sortTrends("date", this.state.sortedBy == "date" ? this.state.sortOrder * -1 : -1)}}>{this.getHeaderText("Date")}</span> </div>
    <div className="trendListItemTraffic"> <span onClick={()=>{this.sortTrends("weight", this.state.sortedBy == "weight" ? this.state.sortOrder * -1 : -1)}}>{this.getHeaderText("weight")}</span> </div>
    
    </div>
<div className="listViewHeadersDivierDiv">
    <hr className="listViewHeadersDivider"/>
</div>
  <div className="trendsListView">
     {this.state.maxFetchedDate != 0 &&
                this.state.trends.map((trend) => {
                  let k = this.state.trends.indexOf(trend);
                  //console.log(trend);

                  let res = (
                    
                    <div key={k} className={"trendListItem" + (trend.visited ? " trendListItemVisited"  : "")}
                     onClick={(e) => {
                      //Util.showTooltip(trend);

                        // Notify the android java app
                        if ("jsiBubbleClick" in window)
                            window.jsiBubbleClick.performClick();
            
                        //trend.visited = true;
                        this.markTrendVisited(k);
                      }} >
        
                        <div>
                            <div className="popupScreen" id="tooltipScreen">
          <div
            className="popupBox" id="tooltip"
            onClick={(event) => {
              event.stopPropagation();
            }}
          >

            <a href={trend.sharelink}
               onClick={(e) => {
              e.preventDefault(); // don't follow link
                     let title = trend.title;
                     let date = trend.date / 1000;
                     let desc = Util.htmlDecode(trend.desc);
                     let url = trend.sharelink;

                     let shareData = {
                             "title" : title + " is trending!",
                             "text" : title + " is trending!\n"+desc+"\n",
                             "url" : url,
                     };

                     // If on app, share natively
                     if ("jsiShareClick" in window) {
                         window.jsiShareClick.performClick(title, desc, url);
                     } else {
                         /*
                         // Fallback to just also display link just in case

                         let linkItem = document.createElement("a");
                         linkItem.classList.add("shareLink");
                         linkItem.innerHTML = url;
                         linkItem.href = url;
                         

                         if (e.target.parentNode.getElementsByClassName("shareLink").length == 0)
                             e.target.parentNode.insertBefore(linkItem, e.target.parentNode.firstChild);
                         */

                         if (navigator.share)
                             navigator.share(shareData);
                         //console.log(shareData);

                     }
              return false;
              }}
              >
            <img className="shareButton btn-vector-icon" 
                 src={btn_share_icon}
                 style={{display : (this.props.appMode && !("jsiShareClick" in window))  ? "none" : "inherit" }}  // Temporary until app
            /> </a>
<div className="listCard">
<div className="listCardUpper">
            <div className="image-container noSelect">
              <div className="circle noSelect">
                <span className="circle__btn noSelect clickable">
                  <div className="image-cropper noSelect">
                    {" "}
                    <img
                      className="noSelect"
                      src={trend.img}
                      alt={trend.title}
                      onClick={(e) => {
                          window.open(
                            "http:///www.google.com/search?tbm=nws&q=" +
                              encodeURIComponent(trend.title).replace(
                                /'/g,
                                "%27"
                              )
                          );
                      }}
                    />
                  </div>
                </span>
                <span className="circle__back-1"></span>
                <span className="circle__back-2"></span>
              </div>
            </div>
            <br />
            <a
              className="info-title"
              style={{
                fontWeight: "bold",
                textDecoration: "underline",
                cursor: "pointer",
              }}
              onClick={(e) => {
                window.open(
                  "http:///www.google.com/search?tbm=nws&q=" +
                    encodeURIComponent(trend.title).replace(
                      /'/g,
                      "%27"
                    )
                );
              }}
            >
              {trend.title}
            </a>
            

<div className="listCardTraffic">    
<meter min="0" max={Util.scales.length-1} value={Util.scales.indexOf(trend.weight)}></meter> 

   <span>{"traffic: " + ( Util.traffic_label(trend.weight))} </span>
   </div>
            <div className="listCardDate">
            <span> {Util.timeConverter(parseInt(trend.date))} </span>
            </div>
</div><div className="listCardLower">
            <span className="description">
              {" "}
              {Util.decodeHtmlSpecial(trend.desc)}{" "}
            </span>
            <br/>
            <a className="articleSourceLink" href={trend.source_url} target="_blank" rel="noopener"> Source : {trend.source} </a>
            <br />
          </div>
</div>
      </div>
      </div>  </div>
                      </div>
                  );
                  // debug
                  //console.log(res.props.title+":"+res.props.y);
                  return res;
                })}
              ;
   
      </div>
      </div>
    );
  }
}




