import React, { Component } from 'react'
import { setTimeWindow } from '../actions'
import store from '../store'
import * as Config from "../config.js"


class TimeWindowSlider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      val_timeWin: Config.DEFAULT_TIMEWIN,
      max_val: Config.MAX_DAYS_BACK,
      min_val: 1,
      step: 1,
    };
  }

  componentDidMount() {
    this.unsubscribe = store.subscribe(() => {
      this.setState({val_timeWin: store.getState().timeWindow});
    });
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  handleChange = (e) => {
    this.setState({[e.target.name]: e.target.value});
  }

  updateValues = (e) => {
    store.dispatch(setTimeWindow(e.target.value));
  }

  render() {
    return (
      <Slider
        min={this.state.min_val}
        max={this.state.max_val}
        step={this.state.step}
        value={this.state.val_timeWin}
        onChange={this.handleChange}
        onUpdate={this.updateValues}
      />
    );
  }
}

const Slider = ({min, max, step, value, onChange, onUpdate}) => (
  <div className="time-slider-container ">
    <input
      type="range"
      name="val_timeWin"
      id="val_timeWin"
      className="rangeslider noSelect"
      min={min}
      max={max}
      step={step}
      value={value}
      onChange={onChange}
      onTouchEnd={onUpdate}
      onMouseUp={onUpdate}
    />
    <label htmlFor="val_timeWin"> Last {value} days </label>
  </div>
);

export default TimeWindowSlider;