import React, { Component } from "react";
import { setCountry } from "../actions";
import store from "../store";
import * as Config from "../config.js";
import * as Util from "../Util.js";
import Cookies from "js-cookie";


const btn_star_icon = "/static/star.png";


class CountrySelector extends Component {
    constructor(props) {
      super(props);
  
      this.state = { selectedCountry: Config.DEFAULT_COUNTRY,
                     favouriteCountries : Util.getFavouriteCountries(),
                     supportedCountries :  Object.keys(Util.country_names)};  
    }
  
    isFavourite(country) { 
      return (this.state.favouriteCountries.indexOf(country) != -1);
    }
  
    getSupportedCountries() {
      let current_order = Object.keys(Util.country_names);
      this.state.favouriteCountries = Util.getFavouriteCountries();
  
      current_order.forEach(country => {
        if (this.isFavourite(country)) {
          current_order.splice(current_order.indexOf(country), 1);
          current_order.unshift(country);
        }
      });
  
      this.setState({supportedCountries: current_order});
  
    }
  
    componentDidMount() {
      let savedCountry = Cookies.get("Country");
      let initCountry = Config.DEFAULT_COUNTRY;
  
      this.getSupportedCountries();
  
      if (Object.keys(Util.country_names).includes(savedCountry)) {
        initCountry = savedCountry;
        
      }
  
  
      this.setState({ selectedCountry: initCountry });
      store.dispatch(setCountry(initCountry));
  
  
      let countryButtons = document.getElementsByClassName("countryListItem");
      for (let i = 0; i < countryButtons.length; i++) {
        if (countryButtons[i].id == initCountry) {
          countryButtons[i].click();
        }
      }
    }
  
    render() {
      return (
        <div className="countryMenu noSelect">
          <div className="countrySelector noSelect" onClick={Util.toggleCountryList}>          
            <img
                  className={"flag " + (this.state.selectedCountry.toLowerCase() == "all" ? "global_flag" : "")}
                    src={
                      Util.getCountryFlag(this.state.selectedCountry) 
                    }
                    alt={Util.country_names[this.state.selectedCountry]}
                  />
                    
          </div>
          <div className="countryList">
            {this.state.supportedCountries.map((item) => {
              return (
                
                <div
                  className="countryListItem"
                  id={item}
                  key={item}
                  onClick={() => {
                      store.dispatch(setCountry(item));
                      this.setState({ selectedCountry: item });
                      Util.hideCountryList();
  
                      // Notify java android app
                      if ("jsiCountryClick" in window)
                          window.jsiCountryClick.performClick();
                      
                  }}
                  onMouseEnter={() => {
                    Util.highlightCountry(item);
                  }}
                >
                  {Util.country_names[item]}
  
                  
                  <img
                  className={"flag " + (item.toLowerCase() == "all" ? "global_flag" : "")}
                    src={Util.getCountryFlag(item)}
                    alt={Util.country_names[item]}
                  />
  
                  <img
                  className={
                    this.isFavourite(item) ? "star_active" : "star"
                  }
                    src={
                      btn_star_icon
                    }
                    onClick={(e) => {
                      let img = e.target;
                      if (this.isFavourite(item)) {
                        // Is already favourited
                        Util.removeFavouriteCountry(item);
                      } else {
                        Util.addFavouriteCountry(item);
                        
                      }
  
                      this.getSupportedCountries();
  
                      e.stopPropagation();
                    }}
                    alt={"Favourite country"}
                  />
                </div>
              );
            })}
          </div>
        </div>
      );
    }
  }
  

  export default CountrySelector;