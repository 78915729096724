import React, { Component } from 'react';
import { setSearchQuery, setTimeWindow } from '../actions';
import store from '../store';
import * as Config from "../config.js";

class SearchBox extends Component {
  
    constructor(props) {
      super(props);
  
      this.state = { "trends_query": "Search..." };
    }
  
    handleChange(e) {
      // These are necessary for updating the slider, otherwise it's stuck on same value
      let obj = {};
      obj[e.target.name] = e.target.value;
      this.setState(obj);
      
    }
  
  
    updateValues(e) {
      // Update value in store
      store.dispatch(setSearchQuery(e.target.value));
    }
  
    render() {
      return (
        <div className="searchContainer">
          
          <input type="text" value={this.state.trends_query} name="trends_query" className="searchTextBox"
           onChange={(e) => {
            this.handleChange(e);
            this.updateValues(e);
          }}
          onClick={(e) => {
            if (e.target.value == "Search...") {
  
                // Because people don't notice the search is bound by max days, automatically set it to max
                store.dispatch(setTimeWindow(Config.MAX_DAYS_BACK));
  
                // Notify java android app
                if ("jsiSearchClick" in window)
                    window.jsiSearchClick.performClick();
                
                e.target.value = "";
                this.handleChange(e);
                this.updateValues(e);
            }
          }}
          />
        </div>
      )
    }
  }
  

  export default SearchBox;