import React, { Component } from 'react'
import Cookies from "js-cookie";

import * as Util from "./Util.js";
import * as viz from "./TrendsViz.js";
import * as Config from "./config.js";

import store from "./store/index";

import TimeWindowSlider from "./components/TimeWindowSlider.js";
import CountrySelector from "./components/CountrySelector.js";
import SearchBox from "./components/SearchBox.js";
import InfoBox, { HelpBox, showHelpBox } from "./components/InfoBox.js";

import "./App.scss";


const logo_top = "/static/logo_top.webp";
const logo_bottom = "/static/logo_bottom.webp";

const btn_ig_icon = "/static/btn_ig.png";
const btn_fb_icon = "/static/btn_fb.png";
const btn_twitter_icon = "/static/btn_twitter.png";
const btn_app_icon = "/static/btn_app.png";
const btn_contact_icon = "/static/btn_contact.png";
const btn_help_icon = "/static/btn_help.png";

const btn_list_view = "/static/btn_list_view.png";
const btn_bubble_view = "/static/btn_bubble_view.png";


window.store = store;
window.tooltip = false;


class ViewType extends Component {
  constructor(props) {
    super(props);

  }

  render() {
    return (<div className="changeViewContainer noSelect">
      <img className={"btn-vector-icon " + (this.props.isListView ? "grayed-icon" : "")} src={btn_bubble_view} onClick={this.props.toggleView} />
      <img className={"btn-vector-icon " + (this.props.isListView ? "" : "grayed-icon")} src={btn_list_view} onClick={this.props.toggleView} />

    </div>);
  }

}


class AppControls extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
  }

  render() {

    return (
      <div className="AppControls container-fluid">
        <TimeWindowSlider />


        <div className="controlContainer">
          <div className="controlCenter">
            <CountrySelector />
            <ViewType
              toggleView={this.props.toggleView}
              isListView={this.props.isListView}
            />
            <SearchBox />
          </div>
        </div>
      </div>
    );
  }
}


class AppBody extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="AppBodyContainer">
        <div className="AppBody">
          {this.props.isListView ?
            <viz.TrendsVizList /> :
            <viz.TrendsVizBubble
              svgWidth={this.props.svgWidth}
              svgHeight={this.props.svgHeight}
              previewTrend={!this.props.previewShown ? this.props.previewTrend : ""} />

          }
        </div>
        <InfoBox
          appMode={this.props.appMode}
        />
        <HelpBox previewTrend={this.props.previewTrend} />
      </div>
    );
  }
}

class AppInfo extends Component {
  constructor(props) {
    super(props);
  }
  render() {

    return (
      <div className="info">

        <div className="infoSegment">
          <div className="App-logo-container">
            <a onLoad={Util.set_logo_spinner} onClick={() => { Util.set_logo_spinner(); Util.loading_finished(); }} ><img src={logo_top} className="App-logo-top" alt="Trendy" /></a>
            <img src={logo_bottom} className="App-logo-bottom" alt="Trendy" />
          </div>
        </div>
      </div>
    );
  }
}



class Footer extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="footer-container">
        <div className="footer-top">
          <ExternalWebsitesIcons />


          <div className="contact-container">
            <a
              onClick={showHelpBox}
              rel="noopener"
              className="contact-link noSelect"
            >
              <img className="btn-vector-icon" src={btn_help_icon} alt="Help!" />
            </a>

            {/* <a
          onClick={() => {
            // Notify java android app
            if ("jsiBuyClick" in window)
              window.jsiBuyClick.performClick();
          }}
          rel="noopener"
            className="contact-link noSelect"
            style={{"display" : this.props.appMode ? "initial" : "none"}}
        >
          <img id="btn-remove-ads" className="btn-vector-icon" src={btn_ads_removal} alt="Support us!"/>
          </a> */}

            &nbsp;
            <a href="mailto:osher@theactual.news" className="contact-link noSelect" onClick={
              (e) => {

                // Notify java android app
                if ("jsiSendMail" in window) {
                  window.jsiSendMail.performClick();
                  e.preventDefault();
                }
              }
            }>
              <img className="btn-vector-icon" src={btn_contact_icon} alt="Contact us!" />
            </a>
          </div>
        </div>
        <div className="footer-bottom">

        </div>
      </div>
    );
  }
}




class ExternalWebsitesIcons extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="external-websites-icons-container">
        <a
          href="https://play.google.com/store/apps/details?id=com.bitsofjoy.trendyapp&utm_source=webFooter"
          target="_blank"
          rel="noopener"
          className="contact-link noSelect"
        >
          <img className="btn-vector-icon" src={btn_app_icon} alt="Download our app!" />
        </a>
        <a
          href="https://www.facebook.com/TrendyWebApp/"
          target="_blank"
          rel="noopener"
          className="contact-link noSelect"
        >
          <img className="btn-vector-icon" src={btn_fb_icon} alt="Follow our Facebook page!" />
        </a>
        <a
          href="https://www.instagram.com/theactual.news/"
          target="_blank"
          rel="noopener"
          className="contact-link noSelect"
        >
          <img className="btn-vector-icon" src={btn_ig_icon} alt="Follow us on Instagram!" />
        </a>
        <a
          href="https://twitter.com/TheActual_News"
          target="_blank"
          rel="noopener"
          className="contact-link noSelect"
        >
          <img className="btn-vector-icon" src={btn_twitter_icon} alt="Follow us on Twitter!" />
        </a>


      </div>
    );
  }
}


class App extends Component {
  constructor(props) {
    super(props);
    this.state = { svgWidth: 0, svgHeight: 0, appMode: true, previewShown: false };

    this.state.appMode = window.location.search.includes("?app");
    this.state.previewTrend = window.location.pathname.includes("/trend/") ? window.location.pathname : "";

    // this.state.androidBrowser = (this.state.appMode == false && navigator.userAgent.match(/Android/i))

    // if (this.state.androidBrowser)
    // window.location.replace("https://play.google.com/store/apps/details?id=com.bitsofjoy.trendyapp&utm_source=webRedirect");


    this.state.isListView = false;
    this.toggleView = this.toggleView.bind(this);
  }

  updateDimensions() {

    if (document.getElementsByClassName("TrendsContainer").length == 0) // No svg on preview mode
      return;

    let h = document.getElementsByClassName("TrendsContainer")[0]
      .clientHeight;
    let w = document.getElementsByClassName("TrendsContainer")[0]
      .clientWidth;
    let scaling = h / Config.SVG_SCALING + w / Config.SVG_SCALING;
    this.setState({ svgWidth: w / scaling, svgHeight: h / scaling });


  }

  componentDidMount() {
    window.addEventListener("resize", () => {
      this.updateDimensions();
    });

    this.updateDimensions(this);

    window.addEventListener("load", function () {
      setTimeout(function () {
        // This hides the address bar:
        window.scrollTo(0, 1);
      }, 0);
    });

  }


  toggleView() {
    this.setState({ isListView: !this.state.isListView, previewShown: true });

  }


  render() {
    //if (this.state.previewTrend)
    //QueryControls = React.Fragment;

    // check window.location.pathname for /trend/ preview

    if (this.state.androidBrowser)
      return null;

    return (
      <div className="App container-fluid">
        <div id="countryListBackground" onClick={(e) => { Util.hideCountryList(); }}></div>
        <header className="App-header container-fluid">

          <AppInfo />

          <AppControls
            toggleView={this.toggleView}
            isListView={this.state.isListView}
          />


        </header>
        <AppBody
          svgWidth={this.state.svgWidth}
          svgHeight={this.state.svgHeight}
          appMode={this.state.appMode}
          previewTrend={this.state.previewTrend}
          previewShown={this.state.previewShown}
          isListView={this.state.isListView}
        />
        <Footer appMode={this.state.appMode} />
      </div>
    );
  }
}

export default App;
