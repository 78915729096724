import Cookies from "js-cookie";
import * as Config from "../config.js";


const initialState = {
    maxDate: Date.now(),
    minDate: Date.now()-1000*60*60*24*Config.DEFAULT_TIMEWIN,
    timeWindow: Config.DEFAULT_TIMEWIN,
    country: Cookies.get("Country") || Config.DEFAULT_COUNTRY,
    trendInfo: { title: "Trendy - Trending Search Analyzer app", date: 0, desc: " \
    Trendy App displays all trending searches and topics in an unique bubble like format, which helps you determine how trending a particular topic is. \
    The bigger a bubble, the more trending it is! \
    Trendy - Trending Topics & Search Trends \
    Find out what\'s trending around the world, browse trending topics & search trends \
    Trendy helps you to find all the trending topics, search headlines and social trends that are currently getting searched the most. Find out what’s trending based on country, date & time. The Trend Analyzer app is useful for content creators, blog writers, producers & enthusiasts in keeping up with what other people are searching the most. \
    Trendy - Trending Search Analyzer app, filters out trending posts, topics and information based on what people are searching around you! The Trendy App displays all trending searches and topics in an unique bubble like format, which helps you determine how trending a particular topic is. The bigger a bubble, the more trending it is! \
    Features of Trendy - Trending Topics & Real-time Search Trends \
      - Find Trending Topics in a particular location or country \
      - Filter Trending Searches based on number of days \
      - Tap on a topic to know more information about the trend \
      - Discover latest trends, news & information before anyone else! \
      - Unique bubble animation for trending topics & trending search results \
      - 100% Free Trending Search Finder App \
      Trendy is powered by Google Trends - which tracks all searches people search on Google and finds out what’s trending in your country / location. Stay updated with trending information, news & updates in real-time. \
      Download Trendy - Discover Trending News & Search Trends at any location. \
      Liked Trendy - Trending Topics & Search Trends? Rate us & leave a review to let us know. \
      If you have any queries, issues, suggestions, or feedback feel free to drop us a message at osher[at]theactual.news and we would get back to you as soon as possible. \
    " },
    searchQuery: "",
};

function rootReducer(state = initialState, action) {
    if (action.type === "SET_COUNTRY") {

        let newCountry = Config.DEFAULT_COUNTRY;
        if (action.payload)  {
            newCountry = action.payload;
        }


        return Object.assign({}, state, {
            country: newCountry
        });
    }

    if (action.type === "SET_TIMEWIN") {
     
        return Object.assign({}, state, {
            timeWindow: action.payload,
            minDate: Date.now() - action.payload*24*60*60*1000
        });
    }

    if (action.type === "SET_SEARCH_QUERY") {
     
        return Object.assign({}, state, {
            searchQuery: action.payload,
        });
    }


    if (action.type === "SET_TREND_INFO") {
     
        return Object.assign({}, state, {
            trendInfo: action.payload,
        });
    }


    return state;
};

export default rootReducer;

