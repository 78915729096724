// src/js/actions/index.js
export function setCountry(payload) {
    
    return { type: "SET_COUNTRY", payload }
};

export function setTimeWindow(payload) {
    return { type: "SET_TIMEWIN", payload }
};

export function setSearchQuery(payload) {
    return { type: "SET_SEARCH_QUERY", payload }
};

export function setTrendInfo(payload) {
    return { type: "SET_TREND_INFO", payload }
};