import React, { Component } from "react";
import store from "../store";
import * as Util from "../Util.js";
import Cookies from "js-cookie";



const btn_share_icon = "/static/share.png";


class InfoBox extends Component {
    constructor(props) {
      super(props);
  
      this.state = {};
      this.state.info = { img: "", title: " Trendy - Trending Search Analyzer app ", date: 0, desc: " \
      Trendy App displays all trending searches and topics in an unique bubble like format, which helps you determine how trending a particular topic is. \
      The bigger a bubble, the more trending it is! \
      Trendy - Trending Topics & Search Trends \
      Find out what\'s trending around the world, browse trending topics & search trends \
      Trendy helps you to find all the trending topics, search headlines and social trends that are currently getting searched the most. Find out what’s trending based on country, date & time. The Trend Analyzer app is useful for content creators, blog writers, producers & enthusiasts in keeping up with what other people are searching the most. \
      Trendy - Trending Search Analyzer app, filters out trending posts, topics and information based on what people are searching around you! The Trendy App displays all trending searches and topics in an unique bubble like format, which helps you determine how trending a particular topic is. The bigger a bubble, the more trending it is! \
      Features of Trendy - Trending Topics & Real-time Search Trends \
        - Find Trending Topics in a particular location or country \
        - Filter Trending Searches based on number of days \
        - Tap on a topic to know more information about the trend \
        - Discover latest trends, news & information before anyone else! \
        - Unique bubble animation for trending topics & trending search results \
        - 100% Free Trending Search Finder App \
        Trendy is powered by Google Trends - which tracks all searches people search on Google and finds out what’s trending in your country / location. Stay updated with trending information, news & updates in real-time. \
        Download Trendy - Discover Trending News & Search Trends at any location. \
        Liked Trendy - Trending Topics & Search Trends? Rate us & leave a review to let us know. \
        If you have any queries, issues, suggestions, or feedback feel free to drop us a message at osher[at]theactual.news and we would get back to you as soon as possible. \
      " };
  
      store.subscribe(() => {
        // Refetch on country change
        this.setState({ info: store.getState().trendInfo });
        //console.log(this.state.info);
      });
    }
  
      closeInfoPopup() {
          document.getElementById("tooltipScreen").style.display = "none";
          if ("jsiCloseInfoPopup" in window)
              window.jsiCloseInfoPopup.performClick();
  
          Util.resetTitleAndDesc();
      }
      
    render() {
      //console.log(this.state);
      return (
        <div className="popupScreen" id="tooltipScreen">
          <div
            id="tooltipBackground"
            onClick={() => {
                this.closeInfoPopup();
            }}
          >
            <div
              className="popupBox" id="tooltip"
              onClick={(event) => {
                event.stopPropagation();
              }}
            >
  
              <span
                className="closeButton"
                onClick={() => {
                    this.closeInfoPopup();
                }}
              >
                x
              </span>
  
              <a href={this.state.info.sharelink}
                 onClick={(e) => {
                e.preventDefault(); // don't follow link
                       let title = this.state.info.title;
                       let date = this.state.info.date / 1000;
                       let desc = Util.htmlDecode(this.state.info.desc);
                       let url = this.state.info.sharelink;
  
                       let shareData = {
                               "title" : title + " is trending!",
                               "text" : title + " is trending!\n"+desc+"\n",
                               "url" : url,
                       };
  
                       // If on app, share natively
                       if ("jsiShareClick" in window) {
                           window.jsiShareClick.performClick(title, desc, url);
                       } else {
                           /*
                           // Fallback to just also display link just in case
  
                           let linkItem = document.createElement("a");
                           linkItem.classList.add("shareLink");
                           linkItem.innerHTML = url;
                           linkItem.href = url;
                           
  
                           if (e.target.parentNode.getElementsByClassName("shareLink").length == 0)
                               e.target.parentNode.insertBefore(linkItem, e.target.parentNode.firstChild);
                           */
  
                           if (navigator.share)
                               navigator.share(shareData);
                           //console.log(shareData);
  
                       }
                return false;
                }}
                >
              <img className="shareButton btn-vector-icon" 
                   src={btn_share_icon}
                   style={{display : (this.props.appMode && !("jsiShareClick" in window))  ? "none" : "inherit" }}  // Temporary until app
              /> </a>
  
              <div className="image-container noSelect">
                <div className="circle noSelect">
                  <span className="circle__btn noSelect clickable">
                    <div className="image-cropper noSelect">
                      {" "}
                      <img
                        className="noSelect"
                        src={this.state.info.img}
                        alt={this.state.info.title}
                        onClick={(e) => {
                            window.open(
                              "http:///www.google.com/search?tbm=nws&q=" +
                                encodeURIComponent(this.state.info.title).replace(
                                  /'/g,
                                  "%27"
                                )
                            );
                        }}
                      />
                    </div>
                  </span>
                  <span className="circle__back-1"></span>
                  <span className="circle__back-2"></span>
                </div>
              </div>
              <br />
              <a
                className="info-title"
                style={{
                  fontWeight: "bold",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
                onClick={(e) => {
                  window.open(
                    "http:///www.google.com/search?tbm=nws&q=" +
                      encodeURIComponent(this.state.info.title).replace(
                        /'/g,
                        "%27"
                      )
                  );
                }}
              >
                {this.state.info.title}
              </a>
              <br />
              <span>{"traffic: " + (Util.traffic_label(this.state.info.weight))} </span>
              <br />
              <span> {Util.timeConverter(parseInt(this.state.info.date))} </span>
              <br />
              <span className="description">
                {" "}
                {Util.decodeHtmlSpecial(this.state.info.desc)}{" "}
              </span>
              <br/>
              <a className="articleSourceLink" href={this.state.info.source_url} target="_blank" rel="noopener"> Source : {this.state.info.source} </a>
              <br />
            </div>
          </div>
        </div>
      );
    }
  }
  
  
  function showHelpBox() {
    //console.log("YO");
    let tooltip = document.getElementById("help");
    let tooltipScreen = document.getElementById("helpScreen");
  
    window.tooltip = true;
    tooltipScreen.style.display = "block";  
  }
  
  
  function hideHelpBox() {
    document.getElementById("helpScreen").style.display = "none";
  }
  
  
  class HelpBox extends Component {
    constructor(props) {
      super(props);
  
      this.state = {
        seenBefore : Cookies.get("helpSeen"),
      };
  
    }
  
    componentDidMount() {
  
      // Help screen only shows up once for new users
      //console.log(this.state);
      if(this.state.seenBefore || this.props.previewTrend) {
        hideHelpBox();
      } else {
        Cookies.set("helpSeen", 1);
      }
    }
  
    render() {
      //console.log(this.state);
  
      return (
        <div className="popupScreen" id="helpScreen">
          <div
            id="tooltipBackground"
            onClick={hideHelpBox}
          >
            <div
              className="popupBox"
              id="help"
              onClick={(event) => {
                event.stopPropagation();
              }}
            >
              <span
                className="closeButton"
                onClick={hideHelpBox}
              >
                x
              </span>
  
              <div className="image-container noSelect">
                <div className="circle noSelect">
                  <span className="circle__btn noSelect">
                    <div className="image-cropper noSelect">
                      {" "}
                      <img
                        className="noSelect logoInfoBox"
                        src={ "/static/logo_info.png"}
                        alt={"Trendy"}
                     
                      />
                    </div>
                  </span>
                  <span className="circle__back-1"></span>
                  <span className="circle__back-2"></span>
                </div>
              </div>
  
              <a
                className="info-title"
                style={{
                  fontWeight: "bold",
                  textDecoration: "none",
                }}
              >
                {"Welcome to Trendy!"}
              </a>
  
              
              <div className="helpDescription">
                <h1 className="info-title"> Trendy is a tool that helps you find trending topics and news. </h1>
                
                <br />
                <span>
                  Each bubble is a daily search trend, a topic that was rapidly
                  rising in search traffic over 24 hours.
                </span>{" "}
                <br />
                <ul>
                  <li>
                    Start by selecting your country from the list to see its
                    local search trends.
                  </li>
                  <li>
                    Adjust the slider to change the selected date range of trends to browse.
                  </li>
                  <li>
                    Type in specific keywords in the search box to look for
                    interesting events.
                  </li>
                  <li>
                    Found an interesting bubble? Tap it to see more info about it!
                  </li>
                  <li>
                    Want know more about selected trend? Tap the article source to read
                    it, or tap the title/image to search it on Google News.
                  </li>
                </ul>
                <br />
                <span>Can't find your country? We're sorry, but since the data is coming from Google Trends, we cannot track countries that don't appear on their Daily Trends section.</span>
  
              </div>
  
            </div>
          </div>
        </div>
      );
    }
  }
  

  export default InfoBox;
  export { HelpBox, showHelpBox };